import APIConstant from 'constants/ApiConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { deleteRequest, getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse } from 'utils/utilities';

class SopStore {
	isSubmittingSop = false;
	isFetchingSop = false;
	isDeletingSop = false;
	rowId = '';
	sopData = [];

	constructor() {
		makeAutoObservable(this);
	}

	submitNewSop = body => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await postRequest({ url: APIConstant.INSERT_SOP, payload: body });
				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					resolve();
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				reject(error.message);
			}
		});
	};

	getAllSop = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await getRequest({ url: APIConstant.GET_ALL_SOP });
				if (response.result?.length > 0) {
					const { result, error } = getValidDataFromResponse({ response, isRequired: false });

					if (!error) {
						resolve();
						this.setSopList(result);
					} else {
						throw new Error(`Something went wrong`);
					}
				} else {
					resolve();
					this.setSopList();
				}
			} catch (error) {
				this.setSopList();
				reject(error.message);
			}
		});
	};

	deleteSop = (sopId, row) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await deleteRequest({ url: `${APIConstant.DELETE_SOP}/${sopId}` });
				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					resolve();
					this.setIsFetchingSop(true);
					this.getAllSop();
					this.setIsFetchingSop();
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				reject();
			}
		});
	};

	setIsSubmittingSop = (value = false) => {
		runInAction(() => {
			this.isSubmittingSop = value;
		});
	};

	setIsDeletingSop = (value = false, rowId = '') => {
		runInAction(() => {
			this.isDeletingSop = value;
			this.rowId = rowId;
		});
	};

	setSopList = (data = []) => {
		runInAction(() => {
			this.sopData = data;
		});
	};

	setIsFetchingSop = (value = false) => {
		runInAction(() => {
			this.isFetchingSop = value;
		});
	};
}

export default SopStore;
