import APIConstant from 'constants/ApiConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { deleteRequest, getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse } from 'utils/utilities';

class MemoStore {
	isSubmittingMemo = false;
	isFetchingMemo = false;
	isDeletingMemo = false;
	rowId = '';
	memoData = [];

	constructor() {
		makeAutoObservable(this);
	}

	submitNewMemo = body => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await postRequest({ url: APIConstant.INSERT_MEMO, payload: body });
				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					resolve();
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				reject(error.message);
			}
		});
	};

	getAllMemo = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await getRequest({ url: APIConstant.GET_ALL_MEMO });
				if (response.result?.length > 0) {
					const { result, error } = getValidDataFromResponse({ response, isRequired: false });

					if (!error) {
						resolve();
						this.setMemoList(result);
					} else {
						throw new Error(`Something went wrong`);
					}
				} else {
					resolve();
					this.setMemoList();
				}
			} catch (error) {
				this.setMemoList();
				reject(error.message);
			}
		});
	};

	deleteMemo = (memoId, row) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await deleteRequest({ url: `${APIConstant.DELETE_MEMO}/${memoId}` });
				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					this.getAllMemo();
					resolve();
				} else {
					throw new Error(`Something went wrong`);
				}
			} catch (error) {
				reject();
			}
		});
	};

	setIsSubmittingMemo = (value = false) => {
		runInAction(() => {
			this.isSubmittingMemo = value;
		});
	};

	setIsDeletingMemo = (value = false, rowId = '') => {
		return new Promise((resolve, reject) => {
			runInAction(() => {
				this.isDeletingMemo = value;
				this.rowId = rowId;
			});
			resolve();
		});
	};

	setMemoList = (data = []) => {
		runInAction(() => {
			this.memoData = data;
		});
	};

	setIsFetchingMemo = (value = false) => {
		runInAction(() => {
			this.isFetchingMemo = value;
		});
	};
}

export default MemoStore;
