import APIConstant from 'constants/ApiConstant';
import notify from 'constants/notificationConstants';
import { makeAutoObservable, runInAction } from 'mobx';
import { getRequest, postRequest } from 'utils/apiUtils';
import { sendNotification } from 'utils/utilities';

class SurveyStore {
	cleaningSurveyReports = [];
	laundrySurveyReports = [];
	isLoadingSurveyReports = false;
	isUploadingSurveyReports = false;
	cleaningSurveySelectedFacilityAndDate = { fId: '', surveyDate: '' };
	laundrySurveySelectedFacilityAndDate = { fId: '', surveyDate: '' };

	constructor() {
		makeAutoObservable(this);
	}

	getCleaningSurveyReports = ({ fId = '', surveyDate = '' }) => {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsLoadingSurveyReports(true);

				const { response, error } = await getRequest({ url: APIConstant.CLEANING_SURVEY_DETAILS, extraParameters: { params: { fac_id: fId, survey_date: surveyDate } } });

				const { result, success, message } = response;

				if (success && !error) {
					this.setCleaningSurveyReports(result);
					sendNotification({ type: notify.success, message });
					resolve();
				} else {
					resolve();
					throw new Error(message);
				}

				this.setIsLoadingSurveyReports();
			} catch (error) {
				this.setIsLoadingSurveyReports();
				this.setCleaningSurveyReports();
				// sendNotification({ type: notify.error, message: notify.something_went_wrong });
				reject();
			}
		});
	};

	getLaundrySurveyReports = ({ fId = '', surveyDate = '' }) => {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsLoadingSurveyReports(true);

				const { response, error } = await getRequest({ url: APIConstant.LAUNDRY_SURVEY_DETAILS, extraParameters: { params: { fac_id: fId, survey_date: surveyDate } } });

				const { result, success, message } = response;

				if (success && !error) {
					this.setLaundrySurveyReports(result);
					sendNotification({ type: notify.success, message });
					resolve();
				} else {
					resolve();
					throw new Error(message);
				}

				this.setIsLoadingSurveyReports();
			} catch (error) {
				this.setIsLoadingSurveyReports();
				this.setLaundrySurveyReports();
				// sendNotification({ type: notify.error, message: notify.something_went_wrong });
				reject();
			}
		});
	};

	uploadSurveyReport = reportData => {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsUploadingSurveyReports(true);

				const { response, error } = await postRequest({ url: APIConstant.UPLOAD_SURVEY_REPORT, payload: reportData });

				const { message, success, result } = response;

				if (success && !error) {
					sendNotification({ type: notify.success, message });
					this.getCleaningSurveyReports({});
					resolve({ result });
				} else {
					throw new Error(message);
				}

				this.setIsUploadingSurveyReports();
			} catch (error) {
				sendNotification({ url: notify.error, message: notify.something_went_wrong });
				this.setIsUploadingSurveyReports();
				reject(error);
			}
		});
	};

	setCleaningSurveyReports = (data = []) => {
		runInAction(() => {
			this.cleaningSurveyReports = data;
		});
	};
	setLaundrySurveyReports = (data = []) => {
		runInAction(() => {
			this.laundrySurveyReports = data;
		});
	};

	setIsLoadingSurveyReports = (value = false) => {
		runInAction(() => {
			this.isLoadingSurveyReports = value;
		});
	};

	setIsUploadingSurveyReports = (value = false) => {
		runInAction(() => {
			this.isUploadingSurveyReports = value;
		});
	};

	setCleaningSurveySelectedFacilityAndDate = data => {
		runInAction(() => {
			this.cleaningSurveySelectedFacilityAndDate = { ...this.cleaningSurveySelectedFacilityAndDate, ...data };
		});
	};

	setLaundrySurveySelectedFacilityAndDate = data => {
		runInAction(() => {
			this.laundrySurveySelectedFacilityAndDate = { ...this.laundrySurveySelectedFacilityAndDate, ...data };
		});
	};
}

export default SurveyStore;
