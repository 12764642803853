import APIConstant from 'constants/ApiConstant';
import { LOGGED_IN_USER_ID, LOGGED_IN_USER_NAME, USER_AUTHENTICATION_TOKEN } from 'constants/AuthConstant';
import { postRequest } from 'utils/apiUtils';
import { isValidArray } from 'utils/utilities';

const { makeAutoObservable, runInAction } = require('mobx');

class AutoLoginStore {
	autoLoginSuccess = false;
	autoLoginInProgress = true;

	constructor() {
		makeAutoObservable(this);
	}

	autoLogin = async url => {
		const queryParams = new URLSearchParams(url);

		let token_id = queryParams.get('token');
		let user_id = queryParams.get('userId');

		const { response, error } = await postRequest({ url: APIConstant.USER_AUTO_LOGIN, payload: { token_id, user_id }, extraParameters: { headers: { 'token-id': token_id } } });

		const { result, success } = response;

		if (!error && success && isValidArray(result)) {
			const [{ token_id, user_id, first_name, last_name }] = result;

			sessionStorage.setItem(USER_AUTHENTICATION_TOKEN, token_id);
			sessionStorage.setItem(LOGGED_IN_USER_ID, user_id);
			sessionStorage.setItem(LOGGED_IN_USER_NAME, first_name + ' ' + last_name);

			runInAction(() => {
				this.autoLoginInProgress = false;
				this.autoLoginSuccess = true;
			});
		}
	};
}

export default AutoLoginStore;
