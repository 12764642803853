import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import Icon from 'components/util-components/Icon';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { LOGGED_IN_USER_NAME } from 'constants/AuthConstant';
import { DATE_FORMAT_DDDD_DD_MMMM_YYYY } from 'constants/DateConstant';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOut } from 'store/slices/authSlice';

const handleUserLogout = navigate => {
	sessionStorage.clear();
	navigate(`${AUTH_PREFIX_PATH}/login`);
};

const MenuItem = props => (
	<Link className="d-flex align-items-center" to={props.path}>
		<Icon className="font-size-md" type={props.icon} />
		<span className="font-weight-normal mx-2">{props.label}</span>
	</Link>
);

const MenuItemSignOut = props => (
	<span className="d-flex align-items-center">
		<LogoutOutlined className="font-size-md" />
		<span className="font-weight-normal mx-2">{props.label}</span>
	</span>
);

export const NavProfile = () => {
	const dispatch = useDispatch();

	const handleClick = ({ key }) => {
		if (key === 'Sign Out') {
			handleSignOut();
		}
	};

	const handleSignOut = () => {
		dispatch(signOut());
	};

	const menu = (
		<Menu
			onClick={handleClick}
			items={[
				{
					key: 'Sign Out',
					label: <MenuItem path={`/logout`} label="Sign Out" icon={LogoutOutlined} />,
				},
			]}
		/>
	);

	return (
		<Dropdown placement="bottomRight" overlay={menu} trigger={['click']}>
			<div className="nav-item">
				<div className="d-flex user_profile align-items-center justify-content-between ">
					<Avatar className="mr-3" src="/img/avatars/thumb-2.jpg" />
					<div className="pl-2 ml-3 d-none d-sm-block profile-text">
						<span className="opacity-0-8">{moment().format(DATE_FORMAT_DDDD_DD_MMMM_YYYY)}</span>
						<div className="font-size-base font-weight-bold">{sessionStorage.getItem(LOGGED_IN_USER_NAME)}</div>
					</div>
				</div>
			</div>
		</Dropdown>
	);
};

export default NavProfile;
