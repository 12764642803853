import APIConstant from 'constants/ApiConstant';
import notify from 'constants/notificationConstants';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { deleteRequest, getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray, sendNotification } from 'utils/utilities';

class KPIInspection {
	kpiReportsData = [];
	loadingKpiData = false;
	isFetchingKpiFieldsByOrg = false;
	isCreatingNewKpiReport = false;
	kpiFieldDetailsByOrg = [];
	copyOfKpiFieldDetailsByOrg = [];

	constructor() {
		makeAutoObservable(this);
	}

	fetchKPIReports = async body => {
		try {
			this.setLoadingKpiData(true);
			const { response, error } = await postRequest({ url: APIConstant.KPI_REPORT, payload: body });

			const { result, message, success } = response;

			if (success && !error && isValidArray(result)) {
				this.setKpiReportsData(result);
			} else {
				this.setKpiReportsData();
			}
			this.setLoadingKpiData();
		} catch (error) {
			runInAction(() => {
				this.setKpiReportsData();
			});
			this.setLoadingKpiData();
		}
	};

	deleteKPIReport = reportId => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await deleteRequest({ url: `${APIConstant.DELETE_KPI_REPORT}/${reportId}` });

				const { error } = getValidDataFromResponse({ response, isRequired: true });

				if (!error) {
					return resolve();
				} else {
					throw new Error('Something went wrong');
				}
			} catch (error) {
				return reject();
			}
		});
	};

	fetchKPIFieldsDetailsByOrgId = orgId => {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsFetchingKpiFieldsByOrg(true);

				const { response } = await getRequest({ url: `${APIConstant.KPI_FIELD_DETAILS_BY_ID}/${orgId}` });
				if (response.result?.length > 0) {
					const { result, error } = getValidDataFromResponse({ response, isRequired: true });

					if (!error && isValidArray(result)) {
						this.setKpiFieldDetailsByOrg(result);
						this.setCopyOfKpiFieldDetailsByOrg(result);
						this.setIsFetchingKpiFieldsByOrg();
						resolve(result);
					} else {
						throw new Error('Something went wrong');
					}
				} else {
					resolve();
				}
			} catch (error) {
				sendNotification({ type: notify.error, message: notify.something_went_wrong });
				reject(error);
			}
		});
	};

	createNewKpiReport = data => {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsCreatingNewKpiReport(true);

				let body = {
					month: moment(data.month).format('MMMM'),
					year: +moment(data.month).format('YYYY'),
					facility_id: data.facility_name,
					kpi_report_pdf: toJS(this.copyOfKpiFieldDetailsByOrg),
				};

				const { response } = await postRequest({ url: APIConstant.CREATE_KPI_REPORT, payload: body });

				const { result, error } = getValidDataFromResponse({ response, isRequired: true });

				if (!error) {
					this.setIsCreatingNewKpiReport(false);
					resolve(result);
				} else {
					throw new Error('Something went wrong');
				}
			} catch (error) {
				sendNotification({ type: notify.error, message: notify.something_went_wrong });
				this.setIsCreatingNewKpiReport();
				reject(error);
			}
		});
	};

	setKpiReportsData = (data = []) => {
		runInAction(() => {
			this.kpiReportsData = data;
		});
	};

	setLoadingKpiData = (val = false) => {
		runInAction(() => {
			this.loadingKpiData = val;
		});
	};

	setIsFetchingKpiFieldsByOrg = (value = false) => {
		runInAction(() => {
			this.isFetchingKpiFieldsByOrg = value;
		});
	};

	setKpiFieldDetailsByOrg = (data = []) => {
		runInAction(() => {
			this.kpiFieldDetailsByOrg = data;
		});
	};

	setCopyOfKpiFieldDetailsByOrg = (data = []) => {
		runInAction(() => {
			this.copyOfKpiFieldDetailsByOrg = data;
		});
	};

	setIsCreatingNewKpiReport = (val = false) => {
		runInAction(() => {
			this.isCreatingNewKpiReport = val;
		});
	};
}

export default KPIInspection;
